
import './components/App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Home from "./components/home";
import Main from './components/main';
import Projects from './components/projects';
import resume from './components/pub/TempletonResumeJanuary2024.pdf'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  
  


  return (
    <Router>
      <Routes>
        <Route
          exact path="/"
          element={<Main />}
        />
        <Route
          exact path="/resume"
          element={resume}
        />
      </Routes>
    </Router>
    
  );
}

export default App;
