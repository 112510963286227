import { useState } from 'react';
import block from './pub/articles/Block.png';
import fiesel from './pub/articles/Feistel.png';

import './App.css';

import 'bootstrap/dist/css/bootstrap.css';

function Projects() {

    return (
        <div>
            <div className="padded row">
                <div className="col-sm-4">
                    <div className='tile'>
                        <img src={block} style={{ padding: "1%", width: "80%", margin: "auto", display: "block" }} />
                        <h3>a</h3>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className='tile'>
                        <img src={fiesel} style={{ padding: "1%", width: "80%", margin: "auto", display: "block" }} />
                        <h3>a</h3>
                    </div>
                </div>
                <div className="col-sm-4">
                    <a>
                        <div className='tile'>
                            <img src={block} style={{ padding: "1%", width: "80%", margin: "auto", display: "block" }} />
                            <h3>a</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Projects;
